import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ११ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ११ वा' num='11'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । तू चराचर व्यापणार । सर्वतोपरी सहाय्य करणार । लेकरांवर कृपा करणार ॥ १ ॥
तूच खरे व्यापणार । पसारा तुझे घर ठरणार । तुझ्या घरात आम्ही रहाणार । पाहुणे नसून लेकुरे होणार ॥ २ ॥
घरातल्या प्रमुखाने नियम करावे । बाकिच्यांनी पाळावे । त्याच्या आज्ञेत रहावे । गुण्यागोविंदाने रहावे ॥ ३ ॥
ऐसा भाव जेथे असतो । संतुष्टपणा तेथे नांदतो । गणामहाराज जाणतो । तेणे आज्ञा मागतो ॥ ४ ॥
आज्ञा शिरसावंद्य होणार । त्यातच हित मानणार । तुझ्यावर भरवसा ठेवणार । तुझ्या वचनात गुंतणार ॥ ५ ॥
ऐसे आज्ञा पालन व्हावे । जेणे नामजप चालू रहावे । विविध अनुभव यावे । मार्गदर्शक ठरावे ॥ ६ ॥
मी आनंद कशात मानतो । तू हे सर्व जाणतो । का पुन्हा पुन्हा विनवण्या करवतो ? । माझा घसा सुकतो ॥ ७ ॥
तू धावून यावे । लेखन कार्या सहाय्य व्हावे । ऐसे लिहिले जावे । भक्तांच्या हृदयावर ठसावे ॥ ८ ॥
गणामहाराज करतो प्रार्थना । जैसे दर्शन दिलेस बाळकृष्णा । तैसाच अनुभव भक्तगणा । कर तू संतुष्ट सर्वांना ॥ ९ ॥
जैसे जवळ केले भास्करास । तैसे सर्व लेकरांस । उणीव तुला वाटल्यास । आणावे निदर्शनास ॥ १० ॥
हा अध्याय अकरावा । सर्वांस बोधपर व्हावा । आधार त्यांस वाटावा । तेणे आधार घ्यावा ॥ ११ ॥
जेथे जैसे मिळणार । तेथे मन धाव घेणार । गुरूजवळ शिष्य येणार । ज्ञानाची भूक भागवणार ॥ १२ ॥
तू कृपाळू कृपावंता । वर्णन होते अनेक ग्रंथा । तुज जवळ आले असता । तूच ठरव आता ॥ १३ ॥
तुझ्याच जवळ येणार । तुझा आधार घेणार । भक्तांसाठी धावणार । जरी दुसरा लाथाडणार ॥ १४ ॥
रामदासे बाळकृष्णा पटवले । वेगळे नको पाहू सांगितले । जरी देहाने दोघे वेगळे । तेणे समर्थ चरण धरले ॥ १५ ॥
बाळकृष्णे समर्थांस । आग्रह केला रहाण्यास । म्हणे यावे बाळापुरास । आनंद होईल आम्हांस ॥ १६ ॥
समर्थे आमंत्रण स्विकारले । बाळापुरला गेले । मनोकामनेस पूर्ण केले । भक्त आनंदित झाले ॥ १७ ॥
भक्तांचा होता सुकाळ । मुख्य बाळकृष्ण नि सुकलाल । अंतरंगी तळमळ । समर्थे जाणली सकळ ॥ १८ ॥
बरोबर होता भास्कर । बाळाभाऊ नि पितांबर । गणु जगदेव बरोबर । सारे सेवेत तत्पर ॥ १९ ॥
एक दिवस उजाडला । वेगळाच तो ठरला । एक कुत्रा पिसाळलेला । जोराने भुंकू लागला ॥ २० ॥
सैरावैरा पळू लागला । जो तो त्यास घाबरला । परी भास्कर पुढे आला । कुत्र्यास हटकू लागला ॥ २१ ॥
प्रारब्ध भोग आडवा आला । भास्कर न जाणता झाला । कुत्रा त्यास चावला । भास्कर विव्हळू लागला ॥ २२ ॥
संचित प्रारब्ध क्रियमाण । न सुटणार जीवा लागून । कधीही भोगल्यावाचून । कैसा भास्कर सुटेल  त्यातून ? ॥ २३ ॥
भास्कराभोवती जमले । सहाय्य करू लागले । त्याने सर्वांस बजावले । समर्थच तारतील भले ॥ २४ ॥
माझा वैद्य गजानन । त्याचा उपाय रामबाण । त्याचे कृपेवाचून । न बरा होणार ह्यातून ॥ २५ ॥
समर्थांपाशी आणले । त्यांस निवेदन केले । एक कुत्रे पिसाळलेले । भास्करास चावले ॥ २६ ॥
समर्थे सर्व जाणले । गायीचे द्वाडपण आडवे आले । दूध पिण्यास मन ललचावले । जेणे गायीस जवळ केले ॥ २७ ॥
पदोपदी विनवले । शांत करण्यास सांगितले । गायीचे रहाणे मठातले । दुध दुभत्याचे विचार मनात आले ॥ २८ ॥
समर्थ भास्करास वदले । गायीचे द्वाडपण नडले । कुत्र्याच्या रूपात चावले । प्रारब्ध भोग कुणा न टळले ॥ २९ ॥
जो चुकवतो ह्या जन्मात । भोगतो पुढिल जन्मात । प्रारब्ध भोग भोगण्यात । भोग कमी होतात ॥ ३० ॥
तुझी ईच्छा असल्यास । गळ घालतो सच्चिदानंदास । भास्कर म्हणाला समर्थांस । नका वाढवू आयुष्यास ॥ ३१ ॥
मी मूढ अज्ञानी । आपण सर्व ज्ञानी । येईल जे जे आपल्या मनी । येऊ द्या आचरणी ॥ ३२ ॥
आपणास दुखणे झाल्यावर । आठवतो वैद्य डॉक्टर । तो जे म्हणणार । तेच इलाज होणार ॥ ३३ ॥
दुःख सहन करावे लागणार । डॉक्टर सांगेल तेच होते । भास्करास माहित होते । तेच बोलके होत होते ॥ ३४ ॥
येथे वैद्य गजानन । त्यावर भरवसा ठेवून । वागण्यात हित जाण । हेच सांगे त्याचे मन ॥ ३५ ॥
समर्थ बाळाभाऊस वदले । भास्करास कुत्रे चावले । एका दृष्टीने बरे झाले । पुनर्जन्माचे क्लेश टळले ॥ ३६ ॥
आयुष्य दोन महिने उरले । बाळाभाऊस पटले । समर्थ फुंकर मारते झाले । त्याचे विव्हळणे थांबले ॥ ३७ ॥
सर्वजण शेगावास आले । गावकर्‍यांस वृत्त कळले । भास्करास पहाण्या आले । खबरबात पुसू लागले ॥ ३८ ॥
त्याने गावकर्‍यांस सांगितले । आता दोन महिने उरले । आमचे सारे संपले । समर्थ ऐसे वदले ॥ ३९ ॥
एक ईच्छा मनास । सांगू लागला गावकर्‍यांस । जैसे ज्ञानेश्वर स्मारक आळंदीस । तुकारामाचे देहूस ॥ ४० ॥
तैसे बांधा शेगावास । समर्थांच्या स्मारकास । विसरू नका गजाननास । आमच्या प. पू. गुरूस ॥ ४१ ॥
हा बोल कुणी न पाळेल । माझा जीव अडकेल । स्मारक बांधण्याचे वचन देता । आत्म्यास शांती लाभेल ॥ ४२ ॥
मरते समयी ईच्छा राहिल्यास । कावळा शिवत नाही पिंडास । माहित होते भास्करास । जेणे सांगितले सर्वांस ॥ ४३ ॥
मरते समयी जीव कशातही अडकतो । जो ज्या चिंतनात रहातो । त्यात जीव अडकतो । कावळा पिंडास न शिवतो ॥ ४४ ॥
त्याचा जीव नव्हता संसारात । जैसा सामान्यांचा प्रपंचात । त्याचा जीव समर्थांत । कळून आले त्याच्या बोलण्यात ॥ ४५ ॥
मायबापांच्याही नशीबात । क्वचित योग असतात । त्यांच्या मरणोत्तर । सुपुत्र स्मारक बांधतात ॥ ४६ ॥
येथे भास्कर नि समर्थ । शिष्य आणि गुरू ह्यांच्यात । प्रेम अतोनात । तेणे उद्गार निघतात ॥ ४७ ॥
धन्य ती जगावेगळी । जगात आदर्श ठरली । त्याची ईच्छा मान्य केली । गावकर्‍यांस महती कळली ॥ ४८ ॥
समर्थ मंदिर जे शेगावात । गुरूभक्ती आदर्श त्यात । नुसती न मूर्ती गाभार्‍यात । मायेचा ओलावा आहे त्यात ॥ ४९ ॥
गुरूसेवा ऐशी करावी । जी आदर्श ठरावी । अंतःकरणाची ओढ असावी । वरकरणी नसावी ॥ ५० ॥
पुढे समर्थ त्र्यंबकेश्वरी गेले । शिवरात्रीस सर्व पोहोचले । कुशावर्तास स्नान केले । गहनीनाथ निवृत्तीनाथ दर्शन घेतले ॥ ५१ ॥
शंकराचे दर्शन घेतले । काळ्या राममंदिरी गेले । गोपाळदासास भेटले । जे धुनी लावुनी बसलेले ॥ ५२ ॥
तेथील मंदिरासमोर । होता पिंपळाचा पार । बैसला भक्त परिवार । होता त्यांच्या समोर ॥ ५३ ॥
गोपाळदास वदे भक्तांस । नारळ फुलहारांस । द्यावे गजाननास । वंदन करावे त्यास ॥ ५४ ॥
सांकेतिक बोलणे झाले । सारे आनंदित झाले । भक्त सर्व जाणते झाले । समर्थांचे अधिकार कळले ॥ ५५ ॥
तीर्थस्थानाच्या महत्वामुळे । येथे येणे भाग पडले । महात्म्य सर्वांस पटवले । तीर्थस्थान लाभले ॥ ५६ ॥
तुम्हीच माझे त्र्यंबकेश्वर । ऐसे एकदा बोलला भास्कर । समर्थ म्हणती त्यावर । त्र्यंबकेश्वर ते त्र्यंबकेश्वर ॥ ५७ ॥
भास्करास पटले । जेणे त्याचे येणे झाले । खर्‍या अर्थी गुरू ठरले । सर्वांचे भाग्य उदयाला आले ॥ ५८ ॥
महाराजांचे भक्त ऐसे होते । अतोनात प्रेम होते । त्यांच्या शब्दावर जगत होते । तेणे घरी नेत होते ॥ ५९ ॥
असाच झ्यामसिंग आडगावचा । हट्ट धरला घरी नेण्याचा । शुद्ध हेतू त्याच्या मनाचा । समर्थे जाणला साचा ॥ ६० ॥
येथे सांगावेसे वाटते । ऐसे असावे गुरू शिष्य नाते । शिष्याने स्वतःहून बोलावण्याते । गुरूने जावे तेथे ॥ ६१ ॥
गुरूला उत्स्फूर्त न्यावे । जाहिरातीस स्थान नसावे । मनोभावे पूजन करावे । गुरूचे थोतांड नसावे ॥ ६२ ॥
समर्थे मन जाणले । घरी जाणे कबूल केले । आडगावात सर्व जमले । अनेक चमत्कार झाले ॥ ६३ ॥
एके दिवशी भर दुपारी । भास्करास बडवू लागली स्वारी । एरव्ही दया झरा अंतरी । शुष्क दिसला येथे भारी ॥ ६४ ॥
त्यास फुफाट्यात लोळवले । बाळाभाऊस न पाहवले । समर्थांस विचारले । बाबा ! ह्याने काय केले ? ॥ ६५ ॥
बाबा ! पुरे, पुरे आता । नका मारू आता । मेलेल्याला काय मारता ? । तो पंथास टेकला आता ॥ ६६ ॥
समर्थ वदले त्यास । तुला छत्रीने बडवण्यास । प्रवृत्त केले आम्हांस । भक्ती खुपत होती त्यास ॥ ६७ ॥
बाळाभाऊचे हृदय द्रवले । भास्करास सोडवले । शत्रुभाव न जागे झाले । मित्रत्वच जागे झाले ॥ ६८ ॥
धन्य तो बाळाभाऊ नि भास्कर । दोघांचे प्रेम एकमेकांवर । जेणे बाळाभाऊस विसर । फुटला दयेचा पाझर ॥ ६९ ॥
जन्मवेळ नि मरणवेळ । चाले पूर्वसंचित खेळ । दोन्हींचा बसण्या मेळ । कर्मच आधार ठरेल ॥ ७० ॥
भास्कराचे पूर्वसंचित । होते चांगले निश्चित । तेणे गुरू सान्निध्यात । होता अखेरच्या श्वासात ॥ ७१ ॥
चैत्र वद्य पंचमी आली । समर्थे वेळ जाणली । वैकुंठनाथाची पाऊले ओळखली । भास्करास नेण्यास आली ॥ ७२ ॥
समर्थे पुढचे जाणले । भास्करास आज्ञापिले । भास्करे वंदन केले । शिरसावंद्य मानले ॥ ७३ ॥
पूर्वाभिमुख तो बसला । पद्मासन घालता झाला । गुरूबोल स्मरता झाला । एकचित्त होता झाला ॥ ७४ ॥
वृत्ती अंतर्मुख झाली । तळमळ शांत झाली । वृत्ती शांत झाली । एकातच स्थिरावली ॥ ७५ ॥
भक्तांनी बुक्का लावला कपाळास । हार घातला गळ्यात । गदगदत्या अंतःकरणास । भरून आले त्यास ॥ ७६ ॥
महाराज समोर बसलेले । गानभजन सुरू झाले । वातावरण दुमदुमले । नयनी अश्रू दाटले ॥ ७७ ॥
पुढचे कळत होते । बोलवत नव्हते । जे सर्वांचे असते । तेच आता घडणार होते ॥ ७८ ॥
थोडे वेगळे होते । पुण्याईशी नाते होते । गुरू कृपाशिष होते । सार्‍यांनाच जाणवत होते ॥ ७९ ॥
महाराजांनी जाणले । मध्यान्हास “हर हर महादेव” म्हणाले । वैकुंठनाथ समोर आले । भास्कराचे जाणे झाले ॥ ८० ॥
सर्व भक्त हळहळले । ढसाढसा रडले । हयातीचे प्रसंग आठवले । कुणी न त्यास अडवले ॥ ८१ ॥
माझा भक्त गेला । उ‌द्गार निघाला । नयनातुन अश्रू टपकला । श्रद्धांजली भाव व्यक्त झाला ॥ ८२ ॥
समर्थे भक्तांस आज्ञा केली । समाधीची तयारी केली । एक मैलावरी द्वारकेश्वराजवळी । समाधीची तयारी झाली ॥ ८३ ॥
एक भक्त वैकुंठास गेला । आत्मा देहातून गेला । देह येथेच शिल्लक राहिला । क्रियाकर्म मागता झाला ॥ ८४ ॥
मरणोत्तरही क्रियाकर्म होई । तिलांजली दिली जाई । भावसुमने अर्पण होई । सर्व शास्त्रानुसार श्रद्धेने केले जाई ॥ ८५ ॥
आत्मा जरी न देहात । आत्म्याच्या अनुसंधानात । सर्व विधी क्रिया होतात । श्रद्धेवर सर्व चालतात ॥ ८६ ॥
अनुभवाने शास्त्रार्थ पटतो । काहीतरी आधार असतो । पिढ्या नि पिढ्या चालतो । कालमानाप्रमाणे जो तो वागतो ॥ ८७ ॥
भास्कराच्या समाधी बाबतीत । पहाते झाले चमत्कृतीत । ऐसे न घडले येथ । जो तो ऐसे म्हणत ॥ ८८ ॥
 चिंचेचे झाड तेथे । समाधीजवळ होते । त्याच्याच सावलीच्या सहाय्याते । अन्नदान होत होते ॥ ८९ ॥
तेथल्या कावळ्यांस । खुपत होते मनास । भास्कर गेला वैकुंठास । हेच कारण असावे मनास ॥ ९० ॥
कावळा पिंडास शिवण्याचे । महत्व न आता त्याचे । तेथल्या अन्नदानाचे । लक्ष वेधले कावळ्यांचे ॥ ९१ ॥
कुणासही न स्वस्थ । जराही न बसू देईनात । व्यत्यय त्यांच्या भोजनात । आणत होते क्षणाक्षणात ॥ ९२ ॥
ते सर्व जाणते कावळे । रोजच्या सवयीने उक्त झालेले । सर्वांस त्रास देते झाले । जे न लोकांस खपले ॥ ९३ ॥
तीरकामठे काढले । कावळ्यांवर धरले । समर्थे दृष्य बघितले । ऐसे करण्यास अडविले ॥ ९४ ॥
समर्थ वदले कावळ्यांना । येऊ नका येथे पुन्हा । नका सतावू कुणा । पुन्हा न यावे स्थाना ॥ ९५ ॥
भास्कर वैकुंठी गेला । तुमचा मान न उरला । जेणे तुम्हा द्वेष झाला । नका आचरू मत्सराला ॥ ९६ ॥
कावळ्यांस सार कळले । गावकर्‍यांस न कळले । ते समर्थांस हसले । त्यांनी वेड्यात काढले ॥ ९७ ॥
खरा वेडा कोण असतो । प्रसंग येता कळतो । आधी शहाणा वेडा ठरतो । परी वेडाच शहाणा ठरतो ॥ ९८ ॥
गावकरी वेडे ठरले । समर्थ वाणीने प्रभावी ठरले । कावळे येणे बंद झाले । समर्थ शहाणे ठरले ॥ ९९ ॥
एकदा शेगावात । दुष्काळ पडला गावात । संपन्नता जी गावात । हळू हळू आली संपुष्टात ॥ १०० ॥
दुष्काळात कामे निघतात । त्यावर काही पोट भरतात । अधिकारी त्यावर चरतात । दया माया विसरतात ॥ १०१ ॥
अधिकार्‍यांची हुकुमत चालते । वरून दडपण येते । चक्र सुरळीत चालू होते । सर्वत्र सारखेच असते ॥ १०२ ॥
वरतुन हुकुम सुटतात । कागदी नाटके होतात । हाताखालचे शेळी होतात । वाघाचे भक्ष बनतात ॥ १०३ ॥
जीव असे पावेतो देहात । गरीब माणसे राबतात । सर्व उपाध्या पत्करतात । एक खळगा भरतात ॥ १०४ ॥
एक विहीर खोदण्यास । मजूर लावले त्यास । पडेल त्या कामास । पत्करावे लागे त्यास ॥ १०५ ॥
दोन पुरूष खोल खणले । काळे दगड दिसू लागले । पहारीचे न उपयोग झाले । खोल खणता न आले ॥ १०६ ॥
चारही बाजूस भोके पाडली । सर्व पहारीने केली । सुरुंगाची मदत घेतली । फोडण्यास सुरूवात झाली ॥ १०७ ॥
चारही बाजूस भोके पाडली । दारू आत ठासून भरली । सुरुंगाची मदत घेतली । एक एक दोरी सरकवली ॥ १०८ ॥
एरंड पुंगळ्या पेटवल्या । दोर्‍यांसवे सोडल्या । मध्येच अडकून बसल्या । पुढे जाईना काही केल्या ॥ १०९ ॥
पुंगळी खाली जाईना । दारूस विस्तव लागेना । पाणी सुरुंग सोडेना । काय करावे सुचेना ॥ ११० ॥
एक मिस्त्री बोलला । त्या गणू जवर्‍याला । जा उतर विहिरीला । थोडे ढकल पुंगळीला ॥ १११ ॥
त्या पुंगळ्या सरकवण्यास । कुणी न धजे त्यास । जेणे मिस्त्रीने गणूस । दरडावून सांगितले त्यास ॥ ११२ ॥
जणू मृत्यूने गणूस । सांगितले विहिरीत उतरण्यास । काळाच्या दटावण्यास । काही न बोले त्यास ॥ ११३ ॥
मृत्यूची चाहूल ओळखली । पटकन उडी घेतली । पुंगळी आत सरकवली । वर येण्याची धडपड केली ॥ ११४ ॥
तोच प्रचंड धडाके झाले । सारे भयभीत झाले । म्हणती गणूचे प्राण गेले । काही न शिल्लक राहिले ॥ ११५ ॥
इकडे गणू एका कपारीत । दडून बसला त्यात । होता धावा करत । होता समर्थां आळवत ॥ ११६ ॥
कपारीत ऐसा बसला होता । काहिही न ईजा होता । गुरू झाला सहाय्य करता । तोच ठरला त्राता । ११७ ॥
समर्थांचा धावा केला । तोच त्यास फळास आला । काळाला घालवता झाला । मृत्यूमुखातुन बाहेर आला ॥ ११८ ॥
कालावधीने तो ओरडला । कुणीतरी आवाज ऐकला । चेष्टेचा खेळ वाटला । परी कानोसा घेतला ॥ ११९ ॥
आश्चर्याचा धक्का बसला । गणूचाच आवाज ऐकला । बाहेर काढण्याचा प्रयत्न केला । गणू त्यातून सुटला ॥ १२० ॥
गणू होता भयभीत । काहितरी पुटपुटत । काहिच नव्हते त्यास कळत । जे जे सर्व होता पहात ॥ १२१ ॥
जो तो होता पुसत । काय केले कपारीस ? । प्रश्र्न त्या अतोनात । शेवटी आला भानात ॥ १२२ ॥
गणूचे चित्त महाराजमय झालेले । त्याला महाराजच दिसले । दुसरे काही न दिसले । म्हणे त्यांनीच मला वाचवले ॥ १२३ ॥
सर्वांना त्याने विनविले । मठाकडे न्या झाले । गणू समवेत काही निघाले । समर्थांचे दर्शन घेतले ॥ १२४ ॥
तो महाराजांस बिलगला । घट्ट धरू लागला । कंठ दाटून आला । हृदयीचा भाव बोलका केला ॥ १२५ ॥
चरणावर डोके ठेवले । चरण घट्ट धरले । महाराज त्यास वदले । गण्या, कपारीत किती धोंडे उडवले ? ॥ १२६ ॥
महाराज म्हणाले त्यास । एकदा तू वाचलास । पुन्हा न करावे साहसास । भिऊ नकोस दटावण्यास ॥ १२७ ॥
महाराजांनी वाचवले गणूस । तैसेच रक्षितात भक्तांस । केवढी काळजी समर्थांस । तैसाच विश्वास भक्तांस ॥ १२८ ॥
गणामहाराज सांगे भक्तांस । जो ठेवतो गुरूवर विश्वास । पाळतो त्यांच्या शब्दास । तो उद्धरतो हमखास ॥ १२९ ॥
भक्तीत स्पर्धा नसावी । गुरूवर श्रद्धा ठेवावी । संचित भोगण्या तयारी ठेवावी । नामात प्रारब्ध सुसह्य होई ॥ १३० ॥
आचरू नये द्वेष मत्सरास । तोच घात करणार । जैसे कर्म आचरणार । तैसेच फळ मिळणार ॥ १३१ ॥
विसरू नका गुरूला । आळवावे त्याला । धावून यावे हाकेला । ऐशा मारावे हाकेला ॥ १३२ ॥
जैसा गुरू लाभला भास्करास । गणू जवर्‍यास । तैसाच लाभो सर्व भक्तगणांस । येथेच अकरावा अध्याय पूर्णत्वास ॥ १३३ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य एकादशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
